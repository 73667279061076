import { Markup } from "interweave"
import React from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"

const Container = styled.div`
  border-radius: 12px;
  background: white;
  box-shadow: 5px 5px 20px #efefef;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
  margin-right: 5%;
  color: ${props => props.theme.darkblue};
  line-height: 30px;
  & > div > h3 > img {
    max-width: 100%;
  }
  & > div > h3 {
    color: ${props => props.theme.midblue};
    margin-top: 40px;
    margin-bottom: 20px;
    :nth-child(1) {
      margin-top: 10px;
    }
  }

  @media ${device.tablet} {
    margin: 0px;
    border-radius: 0px;
  }
`

const Content = styled.p``

const Title = styled.p`
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 18px;
  }
`

const TrattamentoInfoCard = ({ children }) => {
  return <Container>{children}</Container>
}

export default TrattamentoInfoCard
