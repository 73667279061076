import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TrattamentoHeroContainer from "../../containers/trattamenti/trattamento-hero"
import TrattamentoMenu from "../../components/TrattamentoMenu"
import TrattamentoInfoContainer from "../../components/TrattamentoInfoContainer/TrattamentoInfoContainer"
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchTrattamento,
  selectTrattamentoPage,
  selectTrattamentoPageLoading,
} from "../../redux/trattamentoPageSlice"
import LoadingView from "../../components/LoadingView/LoadingView"
import withLocation from "../../utils/withLocation"
import ScrollTopButton from "../../components/ScrollTopButton/ScrollTopButton"

const TrattamentoWrapper = ({ search, location, pageContext: { tratt } }) => {
  const dispatch = useDispatch()
  const trattamento = useSelector(selectTrattamentoPage)
  const loading = useSelector(selectTrattamentoPageLoading)

  useEffect(() => {
    dispatch(fetchTrattamento(tratt.id))
  }, [])

  if (loading) {
    return <LoadingView />
  }
  return (
    <TrattamentoPage
      trattamento={trattamento}
      location={{
        ...location,
        state: { ...location.state, id: trattamento.id_sys },
      }}
      search={search}
    />
  )
}

const TrattamentoPage = ({ location, trattamento }) => {
  const crumbs = [
    {
      crumbLabel:
        trattamento.macrocategoria === "CHIRURGIA ESTETICA"
          ? "Chirurgia Estetica"
          : "Medicina Estetica",
      pathname:
        trattamento.macrocategoria === "CHIRURGIA ESTETICA"
          ? "/chirurgia-estetica"
          : "/medicina-estetica",
      origin: location.origin,
    },
    {
      crumbLabel: trattamento.trattamento || trattamento.title,
      pathname: location.pathname,
    },
  ]
  return (
    <Layout>
      <SEO title={trattamento.trattamento} />
      <BreadCrumbs crumbs={crumbs} />
      <TrattamentoHeroContainer tra={trattamento} />
      <TrattamentoMenu active={0} trattamento={trattamento} />
      <TrattamentoInfoContainer
        trattamento={trattamento}
        title={trattamento.trattamento}
      />
      <ScrollTopButton />
    </Layout>
  )
}

export default withLocation(TrattamentoWrapper)
