import React, { useState } from "react"
import ArrowUpward from "@material-ui/icons/ArrowUpward"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: fixed;
  bottom: 40px;
  height: 70px !important;
  width: 70px !important;
  right: 10%;
  border-radius: 35px !important;
  font-size: 1rem;
  z-index: 1;
  cursor: pointer;
  color: white;
  background: ${props => props.theme.blue};
  align-items: center;
  box-shadow: 1px 1px 4px 1px #201e1e73;
`

const Button = styled(ArrowUpward)`
  position: absolute;
  font-size: 25px;
`

const ScrollButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })
  }

  window.addEventListener("scroll", toggleVisible)

  return (
    <Container
      style={{ visibility: visible ? "visible" : "hidden" }}
      onClick={scrollToTop}
    >
      <Button />
    </Container>
  )
}

export default ScrollButton
