import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Container = styled.div`
  padding-top: 40px;
  padding-bottom: 20px;
  height: 500px;
  width: 100%;
`

export const InfoWindowContainer = styled.div`
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 10px;
  @media ${device.tablet} {
    width: 400px;
  }
`

export const InfoWindowColumn = styled.div`
  border-radius: 12px;
  background-color: white;
  display: flex;

  flex-direction: column;
`

export const InfoWindowImage = styled.div`
  border-radius: 12px;
  height: 155px;
  margin: auto;
  margin-right: 15px;
  background: url(${props => props.img}) center center/cover no-repeat;
  width: 180px;

  @media ${device.tablet} {
    display: none;
  }
`

export const InfoWindowTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  color: #001229;
  margin-bottom: 10px;

  @media ${device.tablet} {
    font-size: 15px;
  }
`

export const InfoWindowSubTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #264a79;
  @media ${device.tablet} {
    font-size: 10px;
  }
`

export const InfoWindowButtons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InfoWindowButton = styled.button`
  border-radius: 6px;
  width: 95px;
  padding: 12px;
  border-width: 0;
  color: white;
  font-weight: 600;

  @media ${device.tablet} {
    width: 90px;
    font-size: 10px;
  }
`
