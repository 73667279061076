import React, { useState } from "react"
import { GoogleMap } from "@react-google-maps/api"
import MapCard from "../MapCard/MapCard"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchLocationsAsync,
  selectMapLoading,
  selectMapLocations,
} from "../../redux/mapSlice"
import { useCurrentPosition } from 'react-use-geolocation';
import { selectPosition } from "../../redux/menuSlice"

const containerStyle = {
  width: "100%",
  height: "400px",
}

export default function GlobalMap({ chirurgo, ...props }) {
  const positions = useSelector(selectMapLocations)
  const loading = useSelector(selectMapLoading)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [center, setCenter] = useState({
    lat: 45.47846,
    lng: 9.21473,
  })

  const position = useSelector(selectPosition)

  React.useEffect(() => {
    if (!loading && chirurgo) {
      setCenter(
        positions
          .map(l => {
            return {
              lng: parseFloat(l.map_lon),
              lat: parseFloat(l.map_lat),
              id: l.id_chi,
            }
          })
          .find(c => c.id == chirurgo.id_sys)
      )
    }
  }, [loading])

  React.useEffect(() => {
  if(position?.coords){
    setCenter({
      lng:position.coords.longitude,
      lat:position.coords.latitude,
      id:position.timestamp
    })
  }
  }, [position])

  React.useEffect(() => {
    dispatch(fetchLocationsAsync())
  }, [])


  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    //map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return <GoogleMap
      {...props}
      mapContainerStyle={containerStyle}
      center={center}
      options={{ disableDefaultUI: true }}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {loading
        ? null
        : positions
            .map(l => {
              return {
                lng: parseFloat(l.map_lon),
                lat: parseFloat(l.map_lat),
                id: l.id_chi,
                id_sys: l.id_sys,
                address: l.indirizzo,
              }
            })
            .map((p,i) => (
              <MapCard
                key={p.id+i}
                visible={visible}
                setVisible={setVisible}
                address={p.address}
                isSmall={!!chirurgo}
                position={p}
                id_sys={p.id_sys}
                id={p.id}
              />
            ))}
    </GoogleMap>
}
