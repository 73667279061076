import { InfoWindow, Marker } from "@react-google-maps/api"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { GET_CHIRURGO } from "../../constants/api"
import { colors } from "../../constants/Colors"
import { getChirurgo } from "../../functions/getChirurgo"
import navigateToChirurgo from "../../functions/navigateToChirurgo"
import { openModal } from "../../redux/layoutSlice"
import MapSection from "../MapSection/MapSection"
import { StarsWithPeople } from "../Stars/Stars"

export default function MapCard({
  isSmall,
  position,
  id,
  id_sys,
  address,
  visible,
  setVisible,
  nome_sede,
}) {
  const [chirurgo, setChirurgo] = useState(null)
  const isVisible = visible == id_sys
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchChirurgo = async () => {
      const { data } = await axios.get(GET_CHIRURGO(id))

      setChirurgo(data[0])
    }

    fetchChirurgo()
  }, [])

  if (isSmall) {
    return (
      <>
        <Marker
          className={"Marker-Google"}
          onClick={() => {
            setVisible(id_sys)
            console.log(id, id_sys)
          }}
          position={position}
        />

        {isVisible && chirurgo && (
          <InfoWindow onCloseClick={() => setVisible(null)} position={position}>
            <MapSection.InfoWindowSubTitle
              style={{ color: colors.DARK_BLUE }}
              onClick={() => navigateToChirurgo(chirurgo)}
            >
              {nome_sede}
              <br />
              <span
                style={{
                  fontSize: 11,
                  color: colors.DARK_BLUE,
                  marginTop: 10,
                  fontWeight: 400,
                }}
              >
                {address}
              </span>
            </MapSection.InfoWindowSubTitle>
          </InfoWindow>
        )}
      </>
    )
  }
  return (
    <>
      <Marker
        className={"Marker-Google"}
        onClick={() => {
          setVisible(id_sys)
          console.log(id, id_sys)
        }}
        position={position}
      />

      {isVisible && chirurgo && (
        <InfoWindow onCloseClick={() => setVisible(null)} position={position}>
          <MapSection.InfoWindow>
            <MapSection.InfoWindowImage img={chirurgo.url_foto} />
            <MapSection.InfoWindowColumn>
              <MapSection.InfoWindowTitle>
                {chirurgo.nome}
              </MapSection.InfoWindowTitle>
              <MapSection.InfoWindowSubTitle>
                {chirurgo.specializzazione}
              </MapSection.InfoWindowSubTitle>
              <div style={{ height: 10 }} />
              <StarsWithPeople fontSize={11} count={chirurgo.rating} />
              <MapSection.InfoWindowButtons>
                <MapSection.InfoWindowButton
                  onClick={() => navigateToChirurgo(chirurgo)}
                  style={{ backgroundColor: "#337AB7" }}
                >
                  Scheda
                </MapSection.InfoWindowButton>
                <MapSection.InfoWindowButton
                  onClick={() => dispatch(openModal(chirurgo))}
                  style={{ backgroundColor: "#264A79" }}
                >
                  Contatta
                </MapSection.InfoWindowButton>
              </MapSection.InfoWindowButtons>
            </MapSection.InfoWindowColumn>
          </MapSection.InfoWindow>
        </InfoWindow>
      )}
    </>
  )
}
