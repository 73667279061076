import React from "react"
import { SectionSubTitle, SectionTitle } from "../../constants/global-styles"
import {
  Container,
  Card,
  InfoWindowContainer,
  InfoWindowTitle,
  InfoWindowSubTitle,
  InfoWindowButton,
  InfoWindowImage,
  InfoWindowColumn,
  InfoWindowButtons,
} from "./styles/MapSec"

const MapSection = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

MapSection.SubTitle = function MapSectionSubTitle({ children, ...restProps }) {
  return <SectionSubTitle {...restProps}>{children}</SectionSubTitle>
}

MapSection.Title = function MapSectionTitle({ children, ...restProps }) {
  return <SectionTitle {...restProps}>{children}</SectionTitle>
}

MapSection.Card = function MapSectionCard({ children, ...restProps }) {
  return <Card {...restProps}>{children}</Card>
}

MapSection.InfoWindow = function MapSectionInfoWindow({
  children,
  ...restProps
}) {
  return <InfoWindowContainer {...restProps}>{children}</InfoWindowContainer>
}

MapSection.InfoWindowImage = function MapSectionInfoWindowImage({
  children,
  ...restProps
}) {
  return <InfoWindowImage {...restProps}>{children}</InfoWindowImage>
}

MapSection.InfoWindowColumn = function MapSectionInfoWindowColumn({
  children,
  ...restProps
}) {
  return <InfoWindowColumn {...restProps}>{children}</InfoWindowColumn>
}

MapSection.InfoWindowTitle = function MapSectionInfoWindowTitle({
  children,
  ...restProps
}) {
  return <InfoWindowTitle {...restProps}>{children}</InfoWindowTitle>
}

MapSection.InfoWindowSubTitle = function MapSectionInfoWindowSubTitle({
  children,
  ...restProps
}) {
  return <InfoWindowSubTitle {...restProps}>{children}</InfoWindowSubTitle>
}

MapSection.InfoWindowButtons = function MapSectionInfoWindowButtons({
  children,
  ...restProps
}) {
  return <InfoWindowButtons {...restProps}>{children}</InfoWindowButtons>
}

MapSection.InfoWindowButton = function MapSectionInfoWindowButton({
  children,
  ...restProps
}) {
  return <InfoWindowButton {...restProps}>{children}</InfoWindowButton>
}

export default MapSection
