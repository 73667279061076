import React from "react"
import styled from "styled-components"
import { Col, Row } from "react-bootstrap"
import SuggestedCol from "../SuggestedCol/SuggestedCol"
import TrattamentoInfoCard from "./TrattamentoInfoCard"
import { Markup } from "interweave"
import StickyBox from "react-sticky-box/dist/esnext"
import useWindowDimensions from "../../hooks/useWindowDimensions"

import { TrattamentoItemContainer } from "../../constants/global-styles"
import GlobalMap from "../GlobalMap/GlobalMap"
import { selectTrattamentoPageChirurghi } from "../../redux/trattamentoPageSlice"
import { useSelector } from "react-redux"

const Container = styled.div`
  background: #fafafa;
  padding-left: 5%;
  padding-right: 5%;
`

export default function TrattamentoInfoContainer({ trattamento }) {
  const { width } = useWindowDimensions()
  const doctors = useSelector(selectTrattamentoPageChirurghi)

  return (
    <TrattamentoItemContainer>
      <Container>
        <Row gx-0>
          <Col sm={12} md={8} xl={9} xxl={10}>
            <TrattamentoInfoCard>
              <Markup content={trattamento.descrizione} />
            </TrattamentoInfoCard>
          </Col>
          <Col sm={12} md={4} xl={3} xxl={2}>
            {width < 767 ? (
              <div>
                <SuggestedCol
                  doctors={doctors}
                  title={trattamento.trattamento}
                  style={{ backgroundColor: "transparent" }}
                />
                <GlobalMap />
              </div>
            ) : (
              <StickyBox offsetTop={100} offsetBottom={20}>
                <SuggestedCol
                  doctors={doctors}
                  title={trattamento.trattamento}
                  style={{ backgroundColor: "transparent" }}
                />
                <GlobalMap />
              </StickyBox>
            )}
          </Col>
        </Row>
      </Container>
    </TrattamentoItemContainer>
  )
}
